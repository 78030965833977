import React, { useState, useEffect } from "react";
import { json, useNavigate } from 'react-router-dom';
import backbutton from '../../assets/assest/back.jpeg'
import Loader from "../loader/loader.js";
import "./choosewhisky.css";

const ChooseWhisky = () => {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [searchData, setSearchData] =useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');

  useEffect(() => {
      window.scrollTo(0, 0); 
      async function fetchData()
      {
        try {
          const response = await fetch('https://admin.smacflavourfolio.com/api/user/whiskeys_list');
          const data = await response.json();
          if(data!=null)
          {
          setApiData(JSON.parse(JSON.stringify(data)));
          setSearchData(JSON.parse(JSON.stringify(data))["data"]);
        //   const mdata=apiData.data[0];
        //   localStorage.setItem('whisky', JSON.stringify(mdata));
          setIsLoading(false);
           //console.log('data',apiData["data"]);
          }
        } catch (error) {
          setIsLoading(false);
          console.error(error);
        }
      }
      fetchData();
  }, []);

  const handleClick= (param,param2,param3) =>
  {
    localStorage.setItem('whiskey_id', param);
    localStorage.setItem('whiskey_name', param2);
    localStorage.setItem('whisky_image',param3);
    console.log(param3);
    localStorage.setItem('is_on_the_nose','0');
    localStorage.setItem('is_on_the_palate','0');
    localStorage.setItem('finish','0');
    localStorage.setItem('completed_nose',null);
    localStorage.setItem('completed_palate',null);
    localStorage.setItem('on_the_palate',null);
    localStorage.setItem('selectedSubcategories',null);
    localStorage.setItem('on_the_nose',null);
    localStorage.setItem('on_the_nose',null);
    localStorage.setItem('on_the_palate',null);
    localStorage.setItem('on_the_nose_rating',null);
    localStorage.setItem('on_the_palate_ratings',null);
    localStorage.setItem('ratings',null);
    localStorage.setItem('review',null);
    localStorage.setItem('rating',null);
    localStorage.setItem('reviee',null);
    navigate("/Mainpage");
  }

  const clickHandle=()=>
  {
    localStorage.setItem('apiData',JSON.stringify(apiData));
    console.log('api data',localStorage.getItem('apiData'));
    navigate("/addWhiskyForm");
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
 
    setQuery(value);
    if(value.length>0)
    {
    const filteredItems = apiData["data"].filter((item) =>
    item["whiskey_name"].toLowerCase().includes(value.toLowerCase())
    );
    console.log('search data',filteredItems);
     setSearchData(filteredItems);
    }
    else
    {
     setSearchData(apiData["data"]); 
    }
  };

  const handlePopstate = () => {
    window.history.back();
  };
  return (
    <div className="dashboardn-background p-2">
      {isLoading ? (
        <Loader />
      ) : (<div
        className="main">
        {/* <br/> */}
        {/* <div className="container"> */}
          <div className="row">
            <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer', float: "left" }}
                 onClick={()=>handlePopstate()}
                >
                <div className='gradient-border'>
                  <img className="gradient-border-img" src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
                </div>
              </button>
            </div>
          </div>
          <div className="container">
            <div style={{ paddingTop:'10px',paddingBottom:'10px'}}>
              <h2>Choose the whisky that you want to evaluate</h2>
            </div>
      
        {/* </div> */}
        
        <div className="cw-header" >
          <div className="row">
            <div className="col-12">
            <input
             type="text"
              className="design_search"
             placeholder="Search..."
             value={query}
             onChange={handleInputChange}
             style={{width:'100%',height:'40px',paddingRight:'5px',paddingLeft:'5px'}}
            />
            </div>
          </div>
        </div>
        </div>
        <br/>
        <section className="container">
          <div className="row" style={{paddingBottom:'50px'}}>
            {searchData.map((data) => (
                <div
                className="col-12"
                style={{
                  textAlign: 'left',
                  paddingLeft: '20px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center', 
                  borderBottom: '1px solid #ddd', 
                  paddingBottom: '10px', 
                  marginBottom: '10px'
                }}
                onClick={() => handleClick(data["id"], data["whiskey_name"], data["icon"]["url"])}
              >
                <div>
                  <img
                    className="bottle-image"
                    src={data["icon"]["url"]}
                    alt={data["whiskey_name"]}
                  />
                </div>
                <div>
                  <p className="truncate" style={{ margin: 0 }}>
                    <b>{data["whiskey_name"]}</b>
                  </p>
                </div>
              </div>
              
            ))}
            </div>
            <div
          className="d-flex container justify-content-center text-align-center"
        >
          
          <button class="fixed-button" onClick={()=>clickHandle()} style={{backgroundColor:'#0A253C',border:'2px solid #0A253C55i'}}>ADD WHISKY <span style={{fontSize:'20px'}}>+</span></button>
          
        </div>
        </section>
      </div>
      )}
    </div>
  );

};

export default ChooseWhisky;
